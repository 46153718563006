import React from "react"

const Success = () => {
  return (
    <section>
      <h1>お問合せありがとうございます。</h1>
      <p>
        お問合せしてから3日以上返信のない場合は恐れいりますが下記のアドレスへご連絡ください。
      </p>
    </section>
  )
}

export default Success
